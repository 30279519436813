@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);

/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

/* Navbar Styles */
nav.navbar {
  background-color: #333;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  width: 120px;
  margin: 0 2em;
}

.navbar-text {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-text i {
  margin-right: 5px;
}

.nav-left, .nav-right {
  display: flex;
  list-style: none;
}

.nav-left li, .nav-right li {
  margin: 0 1em;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  position: relative;
}

nav ul li a:hover {
  color: #003B6F;
}

nav ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #0d80e4;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

nav ul li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Video Section Styles */
.position-relative {
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  background-color: #003B6F;
  padding: 10;
}

.position-absolute .video-top-line {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  font-size: 3em;
}

.position-absolute .video-middle-line {
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  font-weight: 100;
  font-style: normal;
}

.position-absolute .video-bottom-line {
  font-family: "Roboto", sans-serif;
  font-size: 1em; 
  font-weight: 100;
  font-style: normal;
}

@media (max-width: 576px) {
  .position-absolute .video-top-line {
    font-size: 2em; /* Adjusted for smaller screens */
  }

  .position-absolute .video-middle-line {
    padding-bottom: 1px;
    font-size: 1em; /* Adjusted for smaller screens */
  }

  .position-absolute .video-bottom-line {
    font-size: 0.75em; /* Adjusted for smaller screens */
  }

  .position-absolute img {
    width: 60px; /* Adjusted for smaller screens */
    height: auto;
    margin-bottom: 10px;
  }

  .position-absolute {
    padding: 10px; /* Adjusted for smaller screens */
  }
}

.position-absolute {
  position: absolute;
}

.position-absolute img {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 1em;
}

.intro-text .h4 {
  font-size: 1.5em;
}

.intro-text .lead {
  font-size: 1.2em;
}

@media (max-width: 576px) {
  .position-absolute {
    width: 90%;
    padding: 10px;
  }
}

  .position-absolute img {
    width: 100px;
    margin-bottom: 10px;
  }

  .intro-text {
    font-size: 0.8em;
  }

  .intro-text .h4 {
    font-size: 1.2em;
  }

  .intro-text .lead {
    font-size: 1em;
  }

.video-container {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.App-logo {
  width: 200px;
  margin-bottom: 20px;
}

.intro-text p {
  margin: 10px 0;
}

.bordered-text, .subtext {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1.5em;
  color: white;
  background-color: #007bff;
  border: 2px solid #007bff;
  border-radius: 5px;
  text-align: center;
}

/* Expertise Section Styles */
.expertise-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 2em;
  margin-top: 20px;
  background: #fff;
  color: #333;
}

.expertise-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.expertise-box {
  flex: 1;
  border: 2px solid #000;
  padding: 2em;
  margin-right: 2em;
  border-radius: 8px;
  background-color: #ededed;
}

.expertise-information {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.services-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-list ul {
  list-style: none;
  padding: 0;
}

.services-list ul li {
  margin: 1em 0;
  border-bottom: 1px solid #ddd;
  padding: 0.5em 0;
}

.lead {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

/* About Us Section Styles */
.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background: #003B6F /*#007bff*/;
  color: white;
}

.about-us-section p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.about-us-content {
  flex: 1;
  padding: 2em;
  border: 2px solid white;
  margin-right: 2em;
  max-width: 400px;
  text-align: left;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.about-us-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-image img {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
}

.about-us-section h2 {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
}

/* Customers Section Styles */
.customers-section {
  padding: 4em 0;
  background: #f9f9f9;
  text-align: center;
}

.customers-section p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.customers-title {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
}

/* Card Styles */
.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  max-height: 600px;
  object-fit: contain;
  padding: 0px;
}

/* Contact Section Styles */
.contact-section {
  background: #f7f7f7;
  color: #333;
  padding: 4em 0;
}

.contact-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-section h2 {
  font-size: 3em;
  color: #333;
  margin-bottom: 20px;
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;  
}

.contact-section p {
  color: #666;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.contact-details p {
  font-size: 1.5em;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.contact-subheading {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;  
}

.contact-link {
  color: #003B6F;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #ff6347;
}

.contact-section .contact-details i {
  margin-right: 10px;
}

.contact-section .contact-details p {
  animation: fadeIn 1.5s ease-in-out;
}

.contact-section .contact-details p:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Scrolling Logos Styles */
.scrolling-logos {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding: 2em 0;
}

.logos-wrapper {
  display: inline-block;
  animation: scroll 25s linear infinite;
}

.logo-container {
  display: inline-block;
  padding: 0 20px;
}

#customers .customer-logo {
  width: 200px;
  height: 200px;
  /*height: auto;*/
  object-fit: contain;
}

/* Responsive Design for Customer Logos */
@media (max-width: 768px) {
  .customer-logo {
    width: 150px;
    height: 150px; /* Ensures a fixed height for consistency */
  }
}

@media (max-width: 576px) {
  .customer-logo {
    width: 120px;
    height: 120px; /* Ensures a fixed height for consistency */
  }
}

/* Keyframes for scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .background-video {
    display: none;
  }

  .video-overlay {
    background: none;
  }

  .intro-text p {
    font-size: 1.5em;
  }

  .bordered-text {
    font-size: 1.5em;
  }

  .subtext {
    font-size: 1em;
  }

  nav.navbar {
    flex-direction: column;
  }

  .nav-left, .nav-right {
    flex-direction: column;
    align-items: center;
  }

  .nav-left li, .nav-right li {
    margin: 0.5em 0;
  }

  .expertise-section, .about-us-section {
    flex-direction: column;
    align-items: center;
  }

  .expertise-content, .services-list, .about-us-content {
    margin: 1em 0;
    width: 100%;
    max-width: none;
  }

  .about-us-image img {
    width: 100%;
    margin-top: 1em;
  }

  .customer-logo {
    width: auto;
    height: 100px;
    margin: auto;
    object-fit: contain;
    padding: 10px;
  }

  .certifications-grid {
    grid-template-columns: 1fr;
  }

  .contact-form input,
  .contact-form textarea {
    max-width: 100%;
  }

  .map-container {
    margin-top: 20px;
    height: 300px;
  }
}

.expertise-box {
  background: none;
  border: none;
  padding: 2em;
  border-radius: 10px;
  box-shadow: none;
}

.expertise-title {
  font-size: 1.75em;
  color: #333;
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
}

.expertise-title h2 {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.expertise-title p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: red;
}

.expertise-list {
  padding-left: 0;
}

.expertise-list .list-group-item {
  background: #fff;
  border: none;
  font-size: 1.2em;
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-left 0.3s ease;
  position: relative;
}

.expertise-list .list-group-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: #007bff;
  border-radius: 10px 0 0 10px;
  transition: width 0.3s ease;
}

.expertise-list .list-group-item:hover::before {
  width: 10px;
}

.expertise-list .list-group-item:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.why-choose-us-section .card {
  background: #22262b;
  color: white;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-choose-us-title {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
}

.why-choose-us-subheading {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.why-choose-us-section .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.why-choose-us-section .card-img-top {
  width: 100px;
  height: 100px;
  margin: 20px auto;
  object-fit: contain;
}

.why-choose-us-section .card-title {
  font-size: 1.5em;
  margin-top: 10px;
  color: #2d99fd;
  font-weight: bold;
  text-shadow: 0 0 7px #000000;
  transition: color 0.3s ease;
}

.why-choose-us-section .card-text {
  color: #ddd;
  font-size: 1em;
  font-style: italic;
}

.why-choose-us-section .card:hover .card-title {
  color: #ffffff;
}

.why-choose-us-section .card-body {
  padding: 1em;
}

/* Footer Styles */
.footer {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer .col-md-4 {
  margin: 10px 0;
}

.footer h5 {
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.footer p {
  margin: 0;
}

.footer a {
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Service Card Styles */
#services .service-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
  width: 100%;
  height: 450px; /* Adjusted height */
}

#services .service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#services .service-card .card-image-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#services .service-card .service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#services .service-card .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  color: white;
  transition: transform 0.3s ease;
  padding: 20px;
  text-align: left; /* Align text to the left */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align items at the bottom */
  transform: translateY(0%); /* Start from the bottom */
}

#services .service-card:hover .overlay {
  transform: translateY(0); /* Slide up on hover */
}

#services .service-card .overlay .title {
  margin: 0;
  margin-bottom: 10px; /* Add some space below the title */
  font-size: 2em; /* Base font size */
  transition: transform 0.3s ease;
}

#services .service-card:hover .overlay .title {
  transform: translateY(0%); /* Move up on hover */
}

#services .service-card .overlay .description {
  margin-top: 10px;
  font-size: 1.2em; /* Base font size */
  opacity: 0;
  transition: opacity 0.3s ease;
  font-weight: bold;
}

#services .service-card:hover .overlay .description {
  opacity: 1;
  transition-delay: 0.3s;
}

.our-services-title {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  font-size: 2em;
}

/* Media Queries for Further Adjustments */
@media (min-width: 1200px) {
  #services .service-card {
    height: 400px; /* Adjust height for large screens */
  }
  #services .service-card .overlay .title {
    font-size: 1.8em; /* Adjust font size for large screens */
  }
  #services .service-card .overlay .description {
    font-size: 1.1em; /* Adjust font size for large screens */
  }
}

@media (max-width: 768px) {
  #services .service-card {
    height: 450px; /* Maintain height for medium screens */
  }
  #services .service-card .overlay .title {
    font-size: 2.2em; /* Adjust font size for medium screens */
  }
  #services .service-card .overlay .description {
    font-size: 1.4em; /* Adjust font size for medium screens */
  }
}

@media (max-width: 576px) {
  #services .service-card {
    height: 500px; /* Increase height for small screens */
  }
  #services .service-card .overlay .title {
    font-size: 2.5em; /* Increase font size for small screens */
  }
  #services .service-card .overlay .description {
    font-size: 1.5em; /* Increase font size for small screens */
  }
}

/* Heading Section Styles */
.heading-section {
  background-color: #003B6F;
  color: white;
  padding: 3em 0; 
  text-align: center;
  margin-top: 0;
}

.heading-section h2 {
  font-size: 3.5em;
  margin-bottom: 0.5em;
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
}

.heading-section p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 1.25em;
}

.wrapper{
  display: inline-block;
  margin-top: 20px;
}

.link_wrapper{
  position: relative;
  display: inline-block;
}

.link_wrapper a{
  display: block;
  width: 250px;
  height: 50px;
  line-height: 45px;
  font-weight: bold;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #333;
  transition: all .35s;
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  font-size: 1.75em;
}

.icon{
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all .35s;
}

.icon svg{
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: #2ecc71;
  transition: all .35s;
}

.link_wrapper a:hover{
  width: 200px;
  border: 3px solid #2ecc71;
  background: transparent;
  color: #2ecc71;
}

.link_wrapper a:hover + .icon{
  border: 3px solid #2ecc71;
  right: -25%;
}